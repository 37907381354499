/**
 * 数值配置
 * 配置各种策划提供的数值
 *
 */
export default {
  depositDefaultOptions: [
    { get: 6000, price: 6000 },
    { get: 15150, hot: 1, price: 15000 },
    { get: 50750, hot: 1, price: 50000 },
    { get: 102000, hot: 1, price: 100000 },
    { get: 255000, hot: 1, price: 250000 },
    { get: 515000, hot: 1, price: 500000 },
    { get: 1035000, hot: 1, price: 1000000 },
    { get: 2080000, hot: 1, price: 2000000 },
    { get: 5250000, hot: 1, price: 5000000 },
  ],
  firstRechargeAmount: 6000,
  //限时活动
  limitDiscounts: 100000,
  firstRechargeBonusRate: 0.2,
  firstRechargedWithdrawNeedBets: 30000,
  depositActivityAmount: 300000,
  depositActivityMore: 20,
  defaultDepositGot: 150,
  defaultDepositPrice: 150,
  minDepositAmount: 6000,
  maxDepositAmount: 10000000,
  minWithdrawAmount: 10000,
  maxWithdrawAmount: 2000000,
  maxWithdrawDailyAmount: 30000000,
  inviteWheel: {
    targetAmount: 150000,
  },
  luckyDraw: {
    marqueeAmoundList: [20, 50, 80, 100, 500, 250, 80, 20, 50, 50, 20, 20],
  },
  addHomeScreenRewardFrom: 5,
  addHomeScreenRewardTo: 1000,
  gasBurnRatio: 0,
  betGasRatio: 1,
  depositGasRatio: 1,
  useWithdrawLevel: 1,
};
